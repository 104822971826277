<template>
  <FormElement
    v-slot="{ isError, validationListeners }"
    :rules="props.rules"
    :input-value="props.modelValue"
    :hide-details="props.hideDetails"
  >
    <BaseInput
      :label="props.label"
      :label-for-id="parsedId"
      :disabled="props.disabled"
      :is-error="isError"
      is-textarea
    >
      <textarea
        v-bind="$attrs"
        :id="parsedId"
        :value="props.modelValue"
        :rows="props.rows"
        :cols="props.cols"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        v-on="validationListeners"
        @input="onInput"
      />
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </BaseInput>
  </FormElement>
</template>

<script setup lang="ts">
import { computed } from '#imports';
import { getUid } from '@/utils/common';
import { FormElement } from '@/components/inputs/_components/form-element';
import { BaseInput } from '@/components/inputs/_components/base-input';

type Props = {
  modelValue: string;
  id?: string;
  label?: string;
  hideDetails?: boolean;
  rules?: [];
  rows?: number;
  cols?: number;
  placeholder?: string;
  disabled?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const parsedId = computed<string>(() => {
  return props.id ?? `input-${getUid()}`;
});

const onInput = (e: Event) => {
  const target = e.target as HTMLTextAreaElement;

  emit('update:modelValue', target.value);
};
</script>
